import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

const actions = {

}
const mutations = {

}
const state = {

}

const getters = {

}

//创建并暴露store
export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})