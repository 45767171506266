<template>
  <div>
    <img src="@/assets/img/banner5.png" class="tenant_item_banner"/>
    <div class="distinct_box">

      <div class="title1">成为供货商</div>
      <div class="display_box1">
        <div class="display_box1_item">
          <img src="@/assets/img/part.png" class="display_box1_item_img"/>
          <div style="display: inline-block;">
            <div class="supplier_title">合作模式</div>
            <div class="supplier_content">行业领先的数字权益商品供货管理系统，实现构建数字商品销售供应链体系</div>
          </div>

        </div>
        <div class="display_box1_item">
          <img src="@/assets/img/channal_origin.png" class="display_box1_item_img"/>
          <div style="display: inline-block;">
            <div class="supplier_title">基本条件</div>
            <div class="supplier_content">具备虚拟交易类公司资质,具有数字权益商品供货渠道，具备运营、售后服务能力</div>
          </div>
        </div>
      </div>

      <div class="newBox">
        <div class="box">
          <img class="img_css" src="@/assets/img/supplierIn.png"/>
          <div>供应商入驻</div>
        </div>

        <div class="box">
          <img class="img_css" src="@/assets/img/stock.png"/>
          <div>库存管理</div>
        </div>

        <div class="box">
          <img class="img_css" src="@/assets/img/productPublish.png"/>
          <div>商品发布</div>
        </div>

        <div class="box">
          <img class="img_css" src="@/assets/img/channelSale.png"/>
          <div>渠道分销</div>
        </div>

        <div class="box">
          <img class="img_css" src="@/assets/img/finance.png"/>
          <div>财务对账</div>
        </div>

      </div>
    </div>

    <div>

    </div>

    <div class="product_box">
      <div class="title1">申请步骤</div>
      <div class="product_box1">
        <div class="product_box1_item">
          <img src="@/assets/img/write.png" class="product_box1_item_img"/>
          <div>
            <img src="@/assets/img/1.png" class="product_box1_item_img1"/>
          </div>
          <div class="product_box1_item_word">提交申请</div>
        </div>
        <div class="product_box1_item">
          <img src="@/assets/img/business.png" class="product_box1_item_img"/>
          <div>
            <img src="@/assets/img/2.png" class="product_box1_item_img1"/>
          </div>
          <div class="product_box1_item_word">商务洽谈</div>
        </div>
        <div class="product_box1_item">
          <img src="@/assets/img/talk.png" class="product_box1_item_img"/>
          <div>
            <img src="@/assets/img/3.png" class="product_box1_item_img1"/>
          </div>
          <div class="product_box1_item_word">签约合作</div>
        </div>
        <div class="product_box1_item">
          <img src="@/assets/img/online.png" class="product_box1_item_img"/>
          <div>
            <img src="@/assets/img/4.png" class="product_box1_item_img1"/>
          </div>
          <div class="product_box1_item_word">上线运营</div>
        </div>

        <div class="product_box1_item">
          <img src="@/assets/img/training.png" class="product_box1_item_img"/>
          <div>
            <img src="@/assets/img/5.png" class="product_box1_item_img1"/>
          </div>
          <div class="product_box1_item_word">业务培训</div>
        </div>
      </div>
    </div>
    <MyForm3></MyForm3>
  </div>
</template>

<script>

import MyForm3 from "./channel/MyForm3";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Service",
  components: {
    MyForm3
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>

.title1 {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}

.title2 {
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo {
  width: 230px;
  height: 43px;
  margin-right: 116px;
}


.swiper {
  width: 100%;
}

.tenant_item_banner {
  height: 420px;
  width: 100%;

}

.tenant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tenant_item {
  width: 285px;
  height: 120px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tenant_item_img {
  width: 66px;
  height: 66px;
  display: inline-block;

}

.tenant_item_word {
  display: inline-block;
  margin-left: 20px;
}

.title1 {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}

.title2 {
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.display_box1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.display_box1_item {
  width: 587px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  padding-top: 28px;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
}

.distinct_box {
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;

}

.display_box1_item_img {
  width: 120px;
  height: 120px;
  display:inline-block;
}

.display_box1_item_word1 {
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}

.display_box1_item_word2 {
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box {
  text-align: center;
  width: 100%;
}

.product_box {
  background: #FFFFFF;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 54px;
}

.product_box1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product_box1_item {
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  text-align: center;

  width: 240px;
}

.product_box1_item_img {
  width: 120px;
  height: 120px;
  margin-bottom: 34px;
}

.product_box1_item_word {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  text-align: center;

}

.pic1_box {
  height: 400px;
  background: #F7F8FA;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.pic1_box_img {
  width: 389px;
  height: 389px;
}


.pic1_box_word_box {
  width: 600px;
}

.pic1_box_word_box_title1 {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
}

.pic1_box_word_box_title2 {
  width: 68px;
  height: 4px;
  background: #FF385C;
  margin: 21px 0;
}

.pic1_box_word_box_title3 {
  width: 506px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.pic2_box_word_box {
  width: 600px;
}


.pic2_box {
  height: 400px;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 226px;
  background: #FFFFFF;
  justify-content: center;
  align-items: flex-start;
}

.footer_icon {
  width: 60px;
  height: 60px;
  margin-top: 30px;

}

.footer_box1 {
  margin-top: 30px;
  margin-left: 60px;
}

.footer_box2 {
  margin-top: 30px;
  margin-left: 100px;
}

.footer_box1_title {
  height: 36px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 36px;
}

.footer_box1_word {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-top: 18px;
}

.footer_box1_word_tel {
  height: 32px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 32px;
}

.footer_qr_box {
  margin-top: 30px;
  margin-left: 200px;
}

.footer_qr_box_img {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}

.add_css1 {
  margin-left: 330px;
}

.footer_qr_box_word {
  width: 126px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  text-align: center;
}

.fix_box {
  position: fixed;
  top: 50vh;
  right: 5px;
  width: 60px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #F6F6F6;

}

.fix_box_img {
  width: 32px;
  height: 32px;
  text-align: center;
}

.fix_box_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.fix_box_item_word {
  height: 14px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}

.tenant_item_word_1 {
  height: 36px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #111111;
  line-height: 36px;
}

.tenant_item_word_2 {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
}

.header_word {
  width: 120px;
  height: 80px;
  background: #FFFFFF;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 80px;
  text-align: center;

}

.header_word_selected {
  border-top: #FF385C 4px solid;
}


.notice {
  width: 30px;
  height: 30px;
}

.add_css2 {

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 80px;
  text-align: center;
}

.add_css3 {
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #111111;
}

.product_box1_item_img1 {
  width: 36px;
  height: 24px;
}

.co_title {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
}

.newBox {
  margin-top: 20px;
  display: inline-block;
  width: 1200px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content:center;

  .box {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    .img_css {
      width: 90px;
      height: 90px;
    }
  }
}


.supplier_title{
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
}

.supplier_content{
  width: 400px;
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
}
</style>