<template>
	<div class="big_container">
		<img src="@/assets/img/banner7.png" class="img_css" />
		<div class="content_box">
			<div class="menu">
				<el-menu :default-active="activeIndex" class="el-menu-demo" @select="handleSelect">
					<el-submenu index="/cooperation">
						<template slot="title">新闻中心</template>
						<el-menu-item index="1">公司新闻</el-menu-item>
						<el-menu-item index="2">行业新闻</el-menu-item>
						<el-menu-item index="3">客户案例</el-menu-item>
					</el-submenu>
				</el-menu>
			</div>
			<div class="content">
				<div class="ql-container ql-snow" style="border: none;">
					<div v-if="content" v-html="content.article" class="ql-editor">
					</div>
				</div>
				<!-- 	<vue-editor id="editor" useCustomImageHandler @imageAdded="handleImageAdded" v-model="content.article">
				</vue-editor> -->
				<div class="pagination_box">
					<el-pagination layout="prev, pager, next" :total="recordsTotal"
						@current-change="handleCurrentChange" :current-page="start" :page-size="size" hide-on-single-page>
					</el-pagination>
				</div>

			</div>


		</div>
	</div>
</template>

<script>
	// import {
	// 	VueEditor
	// } from "vue2-editor/dist/vue2-editor.core.js";
	import {
		newsList
	} from "@/api/index"
	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: "News",
		data() {
			return {
				activeIndex: '1',
				start: 1,
				size:1,
				recordsTotal: 100,
				content: null,
				test: "<h1>你好，admin</h1>"
			}
		},
		// components: {
		// 	VueEditor,
		// },
		created() {
			this.getData()
		},

		methods: {
			handleSelect(key, keyPath) {
				this.activeIndex = key;
				this.start = 1;
				this.getData()
			},
			getData() {
				let param = {
					start: this.start,
					length: 1,
					category: this.activeIndex
				};
				newsList(param).then((res) => {
					console.log(res)
					this.recordsTotal = res.recordsTotal
					this.content = res.data[0]
					console.log(res)
					this.$nextTick(()=>{
							this.recordsTotal = res.recordsTotal
					})
				})
			},
			handleCurrentChange(val) {
				console.log(val)
				this.start = val
				this.getData()
			},
			handleImageAdded() {
				console.log("upload image");
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "~vue2-editor/dist/vue2-editor.css";
	/* Import the Quill styles you want */
	@import "~quill/dist/quill.core.css";
	@import "~quill/dist/quill.bubble.css";
	@import "~quill/dist/quill.snow.css";

	.big_container {

		background: #F7F8FA;
		width: 100%;

		.img_css {
			width: 100%;
			height: 250px;
		}

		.content_box {
			display: flex;
			flex-direction: row;
			justify-content: center;

			.menu {
				width: 232px;
				background: #FFFFFF;
				margin-right: 20px;
				padding: 20px 0;
			}

			.content {
				width: 952px;
				background: #FFFFFF;
				padding: 20px 40px;
				position: relative;
				
			}


		}

	}

	.pagination_box {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>
