<template>
  <div id="app">
    <el-container class="container">
      <el-header>
        <MyHeader></MyHeader>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer><MyFooter></MyFooter></el-footer>
    </el-container>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader"
import MyFooter from "@/components/MyFooter"
export default {
  name: 'App',
  components: {
    MyHeader,MyFooter
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100vh;
}

.container {
  width: 100%;
  height: 100vh;

  ::v-deep .el-main {
    padding: 0;
  }
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

</style>
