<template>
  <div>
    <img src="@/assets/img/banner6.png" class="img_css"/>
    <div class="aboutBox">
      <div class="aboutBox_main">
        <div class="title">关于淘钱岛</div>
        <div class="line"></div>
        <div class="content">杭州积跬步网络科技有限公司的淘钱岛项目于2021年开始做致力于成为专业、安全本地服务程序提供商。旗下产品包含智慧充值，淘宝客，本地服务等。 以CMS做为数据管理、发布中心，辐射全端，实现多端数据通步，方便
          管理，易操作，摆脱共享模式，实现真正意义上的数据独立。具有独家的一键在线动升级，免去手动打补丁的麻烦。
        </div>
      </div>
      <img src="@/assets/img/taoqiandao.png" class="img_css1"/>
    </div>

    <div class="timeBox">
      <div class="start tag1">
        <div class="tag1Box">
          <div class="tag1Box_title">2022年</div>
          <div class="tag1Box_content">项目内测准备，小程序试营业市场，APP准备上线</div>
        </div>
      </div>
      <div class="line">
      </div>
      <div class="point tag1">
        <div class="tag2Box">
          <div class="tag2Box_title">2021年中旬</div>
          <div class="tag2Box_content">市场调研，分析团队成立，确定淘钱岛第一步方向，项目正式启动</div>
        </div>
      </div>
      <div class="line">
      </div>
      <div class="point tag1">
        <div class="tag1Box">
          <div class="tag1Box_title">2022年</div>
          <div class="tag1Box_content">杭州积跬步网络科技有限公司-本地生活服务平台，成立于2021年，专注于全国本地互联网深耕服务，致力于打造出省钱和赚钱的生活服务平台，</div>
        </div>
      </div>
      <div class="line">
      </div>
    </div>


    <Common4></Common4>

  </div>
</template>

<script>
import Common4 from "@/components/home/Common4";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AboutUs",
  components: {Common4},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.img_css {
  width: 100%;
  height: 250px;
}

.timeBox {
  width: 100%;
  height: 562px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .start {
    width: 16px;
    height: 16px;
    background: #FF385C;
    border-radius: 8px;
  }

  .line {
    width: 2px;
    height: 140px;
    background: #E9E9E9;
    border-radius: 6px;
  }

  .point {
    width: 8px;
    height: 8px;
    background: #BFBFBF;
    border-radius: 4px;
  }

  .tag1 {
    position: relative;

    .tag1Box {
      width: 200px;
      height: 200px;
      position: absolute;
      right: -240px;
      bottom: -200px;

      .tag1Box_title {
        height: 36px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }

      .tag1Box_content {
        margin-top: 10px;
        width: 450px;
        height: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }

    .tag2Box {
      width: 450px;
      height: 200px;
      position: absolute;
      left: -490px;
      bottom: -200px;

      .tag2Box_title {
        text-align: right;
        height: 36px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }

      .tag2Box_content {
        text-align: right;
        margin-top: 10px;
        height: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
  }
}

.img_css1{
  width:450px;
  height:300px;
}
.aboutBox{
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  padding:20px 0;
  .aboutBox_main{
    width:500px;
    .title{
      width: 180px;
      height: 50px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 50px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #FF385C;
      margin:20px 0;
    }
    .content{
      width: 450px;
      height: 120px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }

  }

}
</style>