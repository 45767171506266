<template>
  <div>
    <div class="pic1_box">
      <div class="pic1_box_word_box">
        <div class="pic1_box_word_box_title1">全网实时榜单</div>
        <div class="pic1_box_word_box_title2"></div>
        <div class="pic1_box_word_box_title3">每2小时系统自动更新全网电商销量最好佣金最高的商品榜单，让你省时省力，即可获得全网最新最热的商品数据。</div>
      </div>
      <img src="@/assets/img/pic1.png" class="pic1_box_img"/>
    </div>

    <div class="pic2_box">
      <img src="@/assets/img/pic2.png" class="pic1_box_img"/>
      <div class="pic2_box_word_box">
        <div class="pic1_box_word_box_title1 add_css1">充值多样化</div>
        <div class="pic1_box_word_box_title2 add_css1"></div>
        <div class="pic1_box_word_box_title3 add_css1">各大主流APP，充值套餐多样化，选择性高</div>
      </div>
    </div>

    <div class="pic1_box">
      <div class="pic2_box_word_box">
        <div class="pic1_box_word_box_title1">升级权益返利提升</div>
        <div class="pic1_box_word_box_title2"></div>
        <div class="pic1_box_word_box_title3">淘钱岛依照山姆超市的运作模式，依靠会员费</div>
      </div>
      <img src="@/assets/img/pic3.png" class="pic1_box_img"/>
    </div>

  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common3",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.pic1_box{
  height: 400px;
  background: #F7F8FA;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.pic1_box_img{
  width: 389px;
  height: 389px;
}


.pic1_box_word_box{
  width: 600px;
}
.pic1_box_word_box_title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
}

.pic1_box_word_box_title2{
  width: 68px;
  height: 4px;
  background: #FF385C;
  margin: 21px 0;
}
.pic1_box_word_box_title3{
  width: 506px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.pic2_box_word_box{
  width: 600px;
}


.pic2_box{
  height: 400px;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>