import axios from "axios";
/*import { message,notification } from 'ant-design-vue';*/

export function postRequest(url, data = {},method = "POST",contentType="application/json") {
  let temp = method.toLocaleLowerCase() === 'get' ? 'params' : 'data';
  return new Promise((resolve, reject) => {
    if(contentType == 'application/x-www-form-urlencoded'){
      axios({
        method: method,
        url: url,
        [temp]: data,
        transformRequest: [
          function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'));
            return ret
          }
        ],
        headers: {
          'Content-Type': contentType,
          'Authorization': sessionStorage.getItem("user_token") ? 'Bearer ' + sessionStorage.getItem("user_token") : '',
          'Version': '0.0.1'
        }
      }).then((res) => {
        console.log(res)
        if(res.status == 200){
          resolve(res.data);
 /*         if(res.data.code == 200 || res.data.code == 201){
            resolve(res.data);
          }else if(res.data.code == 444||res.data.code == 300){
      /!*      message.warning(res.data.message);*!/
            reject()
          }else if(res.data.code == 403){
            sessionStorage.clear();
          }
          // 版本过低，重新登录
          else if(res.data.code == 402){
            sessionStorage.clear();
          }*/
        }else{
        /*  notification["error"]({
            message: "错误",
            description: res.statusText || "请求出现错误，请稍后再试",
            duration: 4,
          });*/
        }
      },
      (err) => {
/*        notification["error"]({
          message: "错误",
          description:
            ((err.response || {}).data || {}).message ||
            "连接服务器失败！",
          duration: 4,
        });*/
        reject(err);
      });
    }else{
      axios({
        method: method,
        url: url,
        [temp]: data,
        headers: {
          'Content-Type': contentType,
          'Authorization': sessionStorage.getItem("user_token") ? 'Bearer ' + sessionStorage.getItem("user_token") : ''
        }
      }).then((res) => {
            console.log(res)
        if(res.status == 200){

          resolve(res.data);
      /*    if(res.data.code == 200 || res.data.code == 201){
            resolve(res.data);
          }else if(res.data.code == 444||res.data.code == 300){
         /!*   message.warning(res.data.message);*!/
            reject()
          }else if(res.data.code == 403){
            sessionStorage.clear();
          }*/
        }else{
        /*  notification["error"]({
            message: "错误",
            description: res.statusText || "请求出现错误，请稍后再试",
            duration: 4,
          });*/
        }
      },
      (err) => {
        reject(err);
   /*     notification["error"]({
          message: "错误",
          description:
            ((err.response || {}).data || {}).message ||
            "连接服务器失败！",
          duration: 4,
        });*/
      });
    }
  });
}


