const api = require("@/api/api");
const {postRequest} = require("@/api/request");

module.exports = {
    getCategoryList: function (param) {
        return new Promise(function (resolve, reject) {
            postRequest(api.getCategoryList, param, "GET")
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    provider: function (param) {
        return new Promise(function (resolve, reject) {
            postRequest(api.provider, param, "POST")
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    facilitator: function (param) {
        return new Promise(function (resolve, reject) {
            postRequest(api.facilitator, param, "POST")
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    cooperation: function (param) {
        return new Promise(function (resolve, reject) {
            postRequest(api.cooperation, param, "POST")
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
	newsList: function (param) {
	    return new Promise(function (resolve, reject) {
	        postRequest(api.newsList, param, "GET")
	            .then((res) => {
	                resolve(res);
	            })
	            .catch((err) => {
	                reject(err);
	            });
	    });
	}
};
