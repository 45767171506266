<template>
  <div class="distinct_box">
    <div class="title1">低成本，高收益</div>
    <div class="title2">开启省钱之路</div>
    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/zero_develop.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">零开发</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">无需开发，无需技术</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/high_profit.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">高收益</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">B2c运营模式，直接对接厂家， 极低价格</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/partner.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">成为合伙人</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">渠道专享SAAS系统，轻松获客 长期收益</div>
        </div>
      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/n_add.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">N+次产品升级</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">产品适应市场变化，实时迭代， 推出新功能</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common1",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}
.title2{
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.display_box1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.display_box1_item{
  width: 285px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  text-align: center;
  padding-top: 28px;
}

.distinct_box{
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
}
.display_box1_item_img{
  width: 120px;
  height: 120px;
}
.display_box1_item_word1{
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}
.display_box1_item_word2{
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box{
  text-align: center;
  width: 100%;
}
</style>