<template>
  <div class="container">


    <div class="box">
      <div class="title">填写合作信息</div>
      <div class="inputItem">
        <div class="label">联系人姓名：</div>
        <input v-model="name" class="inputBox"/>

      </div>

      <div class="inputItem">
        <div class="label">联系人手机号：</div>
        <input v-model="phone" class="inputBox"/>
      </div>
      <div class="buttonBox">
        <el-button @click="apply">申请并填报信息</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import Pca from '../../pca/index'
import {facilitator} from "@/api/index"

export default {
  name: "MyForm2",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pca
  },
  data() {
    return {
      name:'',
      phone:''
    }
  },
  methods: {
    apply(){
      facilitator({name:this.name,phone:this.phone,source:1}).then(()=>{
        this.$message.success("申请成功")
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  padding: 100px;

  .title {
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 50px;
    text-align: center;
    margin-bottom: 48px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inputItem {
      width: 500px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 24px;

      .label {
        margin-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        width: 100px;
      }

      .inputBox {
        flex: 1;
        border: transparent;
      }

      .inputBox:focus {
        outline: none;
      }

    }

    .noticeBox {
      width: 500px;
      height: 200px;
      background: #FFFFFF;
      border-radius: 12px;

      .noticeBox_title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF385C;
        line-height: 20px;
        margin-left: 20px;
        margin-top: 30px;
      }

      .noticeBox_content {
        width: 460px;
        margin-top: 10px;
        margin-left: 20px;
        height: 44px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(17, 17, 17, 0.9);
        line-height: 22px;
      }
    }

    .buttonBox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ::v-deep .el-button {
        background: #FF385C;
        color: #FFFFFF;
      }
    }
  }

}
</style>