<template>
  <div class="distinct_box">
    <div class="title1">成为服务商</div>
    <div class="title2">全面型服务，确保用户随时随地体验到淘钱岛的快乐</div>
    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/a/a1.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">导购电商场景</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">覆盖淘宝，京东，拼多多，唯品会，等优惠券，高效转化，商品搜索等众多API</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/a/a2.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">吃喝玩乐场景</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">覆盖影院、外卖、出行、团购等本 地生活CPS返利。吃唱玩乐一站式 消费，省钱又赚钱。</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/a/a3.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">本地服务场景</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">全品头数字权益商品，覆盖话费， 电费，等数字化标准接 </div>
        </div>
      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/a/a4.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">权益特权福利</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">集合电商购物，本地生活，生活服 务，娱乐影视等1000+权益特权,提 供完路的企业福利解决方案</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common4",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}
.title2{
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.display_box1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.display_box1_item{
  width: 285px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  text-align: center;
  padding-top: 28px;
}

.distinct_box{
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
}
.display_box1_item_img{
  width: 120px;
  height: 120px;
}
.display_box1_item_word1{
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}
.display_box1_item_word2{
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box{
  text-align: center;
  width: 100%;
}
</style>