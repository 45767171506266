<template>
  <div>
    <img src="@/assets/img/banner6.png" class="img_css"/>
    <Common5></Common5>
    <Common7></Common7>
    <Common6></Common6>
  </div>
</template>

<script>
import Common5 from "@/components/home/Common5";
import Common6 from "@/components/home/Common6";
import Common7 from "@/components/home/Common7";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Platform",
  components: {Common5,Common6,Common7},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.img_css {
  width: 100%;
  height: 250px;
}

</style>