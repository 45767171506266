<template>
  <div class="distinct_box">
    <div class="title1">一步入驻 即享专业服务</div>
    <div class="title2">全面型服务，确保用户随时随地体验到淘钱岛的快乐</div>
    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/register.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">注册账号</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">微信扫码登录/注册即可</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/createApp.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">创建应用</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">选择相关应用类型进行创建应用</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/debug.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">选择并调试API</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">使用淘钱岛开发平台技术测试应用 </div>
        </div>
      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/toOnline.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">上线发布</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">开发完成在服务市场上发布</div>
        </div>
      </div>
    </div>
    <div class="buttonBox">
      <el-button>申请并填报信息</el-button>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common4",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}
.title2{
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.display_box1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.display_box1_item{
  width: 285px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  text-align: center;
  padding-top: 28px;
}

.distinct_box{
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
}
.display_box1_item_img{
  width: 120px;
  height: 120px;
}
.display_box1_item_word1{
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}
.display_box1_item_word2{
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box{
  text-align: center;
  width: 100%;
}

.buttonBox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ::v-deep .el-button {
    background: #FF385C;
    color: #FFFFFF;
  }
}
</style>