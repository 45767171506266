import Vue from "vue"
import VueRouter from "vue-router"
import Home from "@/components/Home"
import AboutUs from "@/components/about/AboutUs"
import Production from "@/components/Production"
import Cooperation from "@/components/cooperation/index"
import Channel from "@/components/cooperation/Channel"
import Supplier from "@/components/cooperation/Supplier"
import Service from "@/components/cooperation/Service"
import News from "@/components/News"
import Platform from "@/components/Platform"
import Notice from "@/components/Notice"
// import About from "@/components/about/index"
import UserAgreement from "@/components/about/UserAgreement"
import PrivacyPolicy from "@/components/about/PrivacyPolicy"

Vue.use(VueRouter)

const routes = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/aboutUs",
    component: AboutUs,
  },
  {
    path: "/userAgreement",
    component: UserAgreement,
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,
  },
  // {
  //   path: "/about",
  //   component: About,
  //   children: [
  //     {
  //       path: "aboutUs",
  //       component: AboutUs,
  //     },
  //     {
  //       path: "userAgreement",
  //       component: UserAgreement,
  //     },
  //     {
  //       path: "privacyPolicy",
  //       component: PrivacyPolicy,
  //     },
  //   ],
  // },
  {
    path: "/production",
    component: Production,
  },
  {
    path: "/cooperation",
    component: Cooperation,
    children: [
      {
        path: "channel",
        component: Channel,
      },
      {
        path: "supplier",
        component: Supplier,
      },
      {
        path: "service",
        component: Service,
      },
    ],
  },
  {
    path: "/news",
    component: News,
  },
  {
    path: "/platform",
    component: Platform,
  },
  {
    path: "/notice",
    component: Notice,
  },
]
const router = new VueRouter({
  // 这里配置的是路由和组件的映射关系, 是一个数组.
  mode: "history",
  routes,
})

export default router
