<template>
  <div class="container">
    <div class="box">
      <div class="title">填写合作信息</div>

      <div class="inputItem">
        <div class="label">真实姓名：</div>
        <input v-model="name" class="inputBox"/>

      </div>

      <div class="inputItem">
        <div class="label">联系方式：</div>
        <input v-model="phone" class="inputBox"/>
      </div>

      <div class="inputItem">
        <div class="label">公司名称：</div>
        <input v-model="provider_name" class="inputBox"/>
      </div>

      <div class="inputItem">
          <div class="tagBox" v-if="show">
            <div v-for="item in List" :key="item.id" :class="{ 'active': category_id==item.id,'unactive': category_id!=item.id }"  class="tagItem" @click="select(item)" >
              <div class="name">{{item.name}}</div>
              <div class="remark">{{item.remark}}</div>
              <img v-if="category_id==item.id" src="@/assets/img/right.png" class="item_img">
            </div>

          </div>
        <div class="label">您供应的资源：</div>
        <input v-model="category"  class="inputBox"   @focus="show=true" />
<!--        @blur="show=false" @focus="show=true"-->
      </div>
      <div class="inputItem">
        <div class="label">申报说明：</div>
        <input v-model="remark" class="inputBox"/>
      </div>

      <div class="buttonBox">
        <el-button @click="apply">申请并填报信息</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import Pca from '../../pca/index'
import { getCategoryList, provider} from "@/api/index"

export default {
  name: "MyForm3",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pca
  },
  data() {
    return {
      name:'',
      phone:'',
      provider_name:'',
      category_id:'',
      category:'',
      remark:'',
      List: [],
      show:false,
      isActive:false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(){
      getCategoryList().then((res)=>{
        this.List=res.data
      })
    },
    apply(){
      provider({
        name:this.name,
        phone:this.phone,
        provider_name:this.provider_name,
        category_id:this.category_id,
        remark:this.remark,
        source:1
      }).then(()=>{
        this.$message.success("申请成功")
      })
    },
    select(item){
      this.category_id=item.id
      this.category=item.name
      setTimeout(()=>{
        this.show=false
      },2000)
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  padding: 100px;

  .title {
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 50px;
    text-align: center;
    margin-bottom: 48px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inputItem {
      width: 500px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 24px;
      position: relative;
      .label {
        margin-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        width: 100px;
      }

      .inputBox {
        flex: 1;
        border: transparent;
      }

      .inputBox:focus {
        outline: none;
      }

    }

    .noticeBox {
      width: 500px;
      height: 200px;
      background: #FFFFFF;
      border-radius: 12px;

      .noticeBox_title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF385C;
        line-height: 20px;
        margin-left: 20px;
        margin-top: 30px;
      }

      .noticeBox_content {
        width: 460px;
        margin-top: 10px;
        margin-left: 20px;
        height: 44px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(17, 17, 17, 0.9);
        line-height: 22px;
      }
    }

    .buttonBox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ::v-deep .el-button {
        background: #FF385C;
        color: #FFFFFF;
      }
    }
  }

}

  .tagBox{
    z-index: 5000;
    position:absolute;
    left: 0;
    top: 60px;
    z-index:1000;
    max-height: 300px;
    overflow: scroll;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tagItem{
      width: 220px;
      height: 66px;
      border-radius: 8px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .item_img{
        position: absolute;
        right: -1px;
        bottom: 0;
        width: 16px;
        height: 16px;
      }
      .remark{
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 17px;
      }
      .name{
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }

    }
  }

::-webkit-scrollbar {
  display: none; /* Chrome Safari */

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

.active{
border: #FF385C 1px solid;
  background: #FFF3F6;
}
.unactive{
  background: #F7F7F7;
}
</style>