<template>
  <div class="container">
    <div class="inner_container" @mouseenter="inD" @mouseleave="outD">
      <div>
        <div class="title">CPS系统</div>
        <el-menu :default-active="activeIndex1" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1-1">淘钱岛小程序</el-menu-item>
          <el-menu-item index="1-2">淘钱岛APP</el-menu-item>
          <el-menu-item index="1-3">外卖COS系统</el-menu-item>
          <el-menu-item index="1-4">加油充电CPS系统</el-menu-item>
          <el-menu-item index="1-5">电影票CPS系统</el-menu-item>
        </el-menu>
      </div>

      <div>
        <div class="title">销售系统</div>
        <el-menu :default-active="activeIndex2" mode="horizontal" @select="handleSelect">
          <el-menu-item index="2-1">电话机器人</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ChildMenu",
  data() {
    return {
      activeIndex1:'',
      activeIndex2:''
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    inD(){
      this.$emit('change',true)
    },
    outD(){
      this.$emit('change',false)
    },
  }
}
</script>

<style lang="scss" scoped>
 .container {
  width:0px;
  height:0px;
   position:relative;
   .inner_container{
     position:absolute;
     top: 0;
     left:-20px;
     display: flex;
     flex-direction: row;
     align-items: flex-start;
     background:#FFFFFF;
     z-index: 10000;
     padding:16px;
     box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
     border-radius: 0 0 12px 12px;

     .title{
       height: 24px;
       font-size: 16px;
       font-family: PingFangSC-Semibold, PingFang SC;
       font-weight: 600;
       color: rgba(17, 17, 17, 0.9);
       line-height: 24px;
     }
   }


}
</style>