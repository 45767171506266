<template>
  <div class="product_box">
    <div class="title1">产品矩阵</div>
    <div class="title2">淘钱岛依照山姆超市的运作模式，依靠会员费</div>
    <div class="product_box1">
      <div class="product_box1_item">
        <img src="@/assets/img/application.png" class="product_box1_item_img"/>
        <div class="product_box1_item_word">APP</div>
      </div>
      <div  class="product_box1_item">
        <img src="@/assets/img/web.png" class="product_box1_item_img"/>
        <div class="product_box1_item_word">小程序</div>
      </div>
      <div  class="product_box1_item">
        <img src="@/assets/img/people.png" class="product_box1_item_img"/>
        <div class="product_box1_item_word">公众号</div>
      </div>
      <div  class="product_box1_item">
        <img src="@/assets/img/more.png" class="product_box1_item_img"/>
        <div class="product_box1_item_word">更多</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common2",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}
.title2{
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}
.product_box{
  background: #FFFFFF;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 54px;
}

.product_box1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.product_box1_item{
  width: 285px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  text-align: center;
}

.product_box1_item_img{
  width: 120px;
  height: 120px;
  margin-bottom: 34px;
}
.product_box1_item_word{
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 25px;
  text-align: center;

}
</style>