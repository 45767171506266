<template>
  <div class="header">
    <img src="@/assets/img/logo.png" class="logo">

    <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="/home">首页</el-menu-item>
      <el-menu-item index="/production" @mouseenter.native="showMenu" @mouseleave.native="closeMenu">
        <span>产品中心</span>
        <ChildMenu v-if="show" @change="change"></ChildMenu>
      </el-menu-item>
      <el-submenu index="/cooperation">
        <template slot="title">合作代理</template>
        <el-menu-item index="/cooperation/channel">渠道商</el-menu-item>
        <el-menu-item index="/cooperation/service">服务商</el-menu-item>
        <el-menu-item index="/cooperation/supplier">供货商</el-menu-item>
      </el-submenu>
      <!--      <el-menu-item index="/">合作代理</el-menu-item>-->
      <el-menu-item index="/platform">开放平台</el-menu-item>
      <el-menu-item index="/news">新闻资讯</el-menu-item>
      <el-menu-item index="/aboutUs">关于我们</el-menu-item>
    </el-menu>
    <el-badge :value="9" class="item" style="margin-left: 20px;" @click.native="jumpNotice">
      <img src="@/assets/img/notice.png" class="notice_css" @click="jumpNotice">
    </el-badge>

  </div>
</template>

<script>
import ChildMenu from "@/components/home/ChildMenu";

export default {
  name: "MyHeader",
  components: {ChildMenu},
  data() {
    return {
      activeIndex: '1',
      show: false
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    showMenu() {
      this.show = true
    },
    closeMenu() {
      this.show = false
    },
    change(params) {
      console.log(params)
      this.show = params

    },
    jumpNotice(){
      console.log("//")
      this.$router.push({path: '/notice'})
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo {
  width: 230px;
  height: 43px;
  margin-right: 116px;
}

.notice_css{
  width:20px;
  height:20px;
}

.item {
  cursor: pointer;
}

</style>