<template>
  <div>
    <div class="footer">
      <img src="@/assets/img/foot_icon.png" class="footer_icon" />
      <div class="footer_box1">
        <div class="footer_box1_title">产品</div>
        <div class="footer_box1_word">淘钱岛app</div>
        <div class="footer_box1_word">淘钱岛小程序</div>
        <div class="footer_box1_word">微信公众号</div>
      </div>
      <div class="footer_box2">
        <div class="footer_box1_title">关于</div>
        <div class="footer_box1_word">成为合伙人</div>
        <div class="footer_box1_word">关于我们</div>
        <div class="footer_box1_word">
          <router-link to="/userAgreement">用户协议</router-link>
        </div>
        <div class="footer_box1_word">
          <router-link to="/privacyPolicy">隐私政策</router-link>
        </div>
      </div>
      <div class="footer_box2">
        <div class="footer_box1_title">联系我们</div>
        <div class="footer_box1_word">客服微信</div>
        <div class="footer_box1_word_tel">itaoqiandao</div>
      </div>

      <div class="footer_qr_box">
        <img src="@/assets/img/wx.jpg" class="footer_qr_box_img" />
        <div class="footer_qr_box_word">微信扫码进入</div>
        <div class="footer_qr_box_word">获取淘钱岛最新资讯</div>
      </div>
    </div>
    <div class="foot_word" @click="jump">
      Copyright 2021-2022© 版权所有www.taoqiandao.com&nbsp;&nbsp; 网站备案号：浙ICP备2022002547号-1
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "MyFooter",
  data() {
    return {}
  },
  methods: {
    jump() {
      console.log("实现了跳转")
      window.location.href = "https://beian.miit.gov.cn"
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 230px;
  background: #ffffff;
  justify-content: center;
  align-items: flex-start;
}

.footer_icon {
  width: 60px;
  height: 60px;
  margin-top: 30px;
}

.footer_box1 {
  margin-top: 30px;
  margin-left: 60px;
}

.footer_box2 {
  margin-top: 30px;
  margin-left: 100px;
}

.footer_box1_title {
  height: 36px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 36px;
}

.footer_box1_word {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-top: 18px;
  a{
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
}

.footer_box1_word_tel {
  height: 32px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 32px;
}

.footer_qr_box {
  margin-top: 30px;
  margin-left: 200px;
}

.footer_qr_box_img {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}

.add_css1 {
  margin-left: 330px;
}

.footer_qr_box_word {
  width: 126px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  text-align: center;
}

.fix_box {
  position: fixed;
  top: 50vh;
  right: 5px;
  width: 60px;
  height: 120px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #f6f6f6;
}

.fix_box_img {
  width: 32px;
  height: 32px;
  text-align: center;
}

.fix_box_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.fix_box_item_word {
  height: 14px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}

.tenant_item_word_1 {
  height: 36px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #111111;
  line-height: 36px;
}

.tenant_item_word_2 {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
}

.header_word {
  width: 120px;
  height: 80px;
  background: #ffffff;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 80px;
  text-align: center;
}

.header_word_selected {
  border-top: #ff385c 4px solid;
}

.notice {
  width: 30px;
  height: 30px;
}

.add_css2 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 80px;
  text-align: center;
}

.add_css3 {
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #111111;
}

.header_word:hover {
  background: #ff385c;
}

.foot_word {
  text-align: center;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
  color: #666666;
}
</style>
