<template>
  <div class="tenant">
    <div class="tenant_item">
      <img src="@/assets/img/taobao.png" class="tenant_item_img"/>
      <div class="tenant_item_word">
        <div class="tenant_item_word_1">
          淘宝联盟
        </div>
        <div class="tenant_item_word_2">
          阿里巴巴集团
        </div>
      </div>
    </div>

    <div class="tenant_item">
      <img src="@/assets/img/jindong.png" class="tenant_item_img"/>
      <div class="tenant_item_word">
        <div class="tenant_item_word_1">
          京东联盟
        </div>
        <div class="tenant_item_word_2">
          品质保证
        </div>
      </div>
    </div>

    <div class="tenant_item">
      <img src="@/assets/img/pinduoduo.png" class="tenant_item_img"/>
      <div class="tenant_item_word">
        <div class="tenant_item_word_1">
          多多进宝
        </div>
        <div class="tenant_item_word_2">
          物美价廉
        </div>
      </div>
    </div>

    <div class="tenant_item">
      <img src="@/assets/img/weipinhui.png" class="tenant_item_img"/>
      <div class="tenant_item_word">
        <div class="tenant_item_word_1">
          唯品会联盟
        </div>
        <div class="tenant_item_word_2">
          天天有折扣
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Tenant",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.tenant_item_banner{
  height: 420px;
  width: 100%;

}
.tenant{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tenant_item{
  width: 285px;
  height: 120px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tenant_item_img{
  width: 66px;
  height: 66px;
  display: inline-block;

}
.tenant_item_word{
  display: inline-block;
  margin-left: 20px;
}

</style>