<template>
  <div class="distinct_box">
    <div class="title1">成为服务商</div>
    <div class="title2">淘钱岛依照山姆超市的运作模式，依靠会员费，给大家提供最优质的服务</div>

    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/b/b1.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">海量接口</div>
          <div class="supplier_content">一站式对接电商、本地生活、品牌卡券接口，覆盖商品、用户 交易等模块</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/b/b2.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">省时省力</div>
          <div class="supplier_content">提供聚台优惠券商城核心系統能力，大大节省人力和时间双成本</div>
        </div>
      </div>
    </div>

    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/b/b3.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">专业团队</div>
          <div class="supplier_content">淘宝联盟核心团队出身，服务过众多顶级媒体，全程专人指导 避免踩坑。</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/b/b4.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">个性化定制和服务</div>
          <div class="supplier_content">提供定制化需求服务，打造独一无二的聚合南城，满足特色化 丝营需求</div>
        </div>
      </div>
    </div>


    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/b/b5.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">公开透明</div>
          <div class="supplier_content">收益直入帐号，数据透明可视，做到有据可依有 数可查.</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/b/b6.png" class="display_box1_item_img"/>
        <div style="display: inline-block;">
          <div class="supplier_title">增值服务</div>
          <div class="supplier_content">淘钱岛开放平台全咨源付费春户，在服务周期内可免费持緛接 入新 入咨源</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common7",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.title1 {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}

.title2 {
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.distinct_box {
  background: #FFFFFF;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
}
.display_box1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.display_box1_item {
  width: 587px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  padding-top: 28px;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
}

.distinct_box {
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;

}

.display_box1_item_img {
  width: 120px;
  height: 120px;
  display:inline-block;
}

.display_box1_item_word1 {
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}

.display_box1_item_word2 {
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box {
  text-align: center;
  width: 100%;
}
</style>