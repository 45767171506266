<template>
  <div class="container">
    <img src="@/assets/img/banner6.png" class="img_css"/>
    <div class="content">
      <div style="font-size:20px; font-weight: 600;">
        平台公告
      </div>
      <div style="width:100%;height:1px;background:#E9E9E9;margin-top:10px;"></div>
      <div v-for="(item,index) in List" :key="index">
        <div class="item">
          <div class="item_content">{{ item.content }}</div>
          <div class="item_time">{{ item.time }}</div>
        </div>
        <div style="width:100%;height:1px;background:#E9E9E9;margin-top:10px;"></div>
      </div>
      <div class="pagination_box">
        <el-pagination
            layout="prev, pager, next"
            :total="1000">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notice",
  components: {},
  data() {
    return {
      List: [
        {content: "淘钱岛将于四月底开启内部评测", time: "2022-04-22  18:12"},
        {content: "凉茶  300ml", time: "2022-04-22  18:12"},
        {content: "好吃的薯片", time: "2022-04-22  18:12"},
        {content: "特别好吃的蛋卷", time: "2022-04-22  18:12"},

      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.img_css {
  width: 100%;
  height: 250px;
}

.container {
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  margin: 10px 0;
  width: 900px;
  height: 849px;
  background: #FFFFFF;
  padding: 20px 40px;
  position: relative;

  .pagination_box {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.item {
  display: flex;
  flex-direction: row;
 justify-content: space-between;
  width:100%;
  padding:15px 0;
  .item_content{
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 24px;
  }

  .item_time{
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }

}
</style>