<template>
  <div class="container_a">
    <el-select v-model="provinceValue" placeholder="省" @change="selectProvimce">
      <el-option
          v-for="(item,index) of provincearr"
          :key="index"
          :label="item.name"
          :value="item.id"
      ></el-option>
    </el-select>
    <el-select v-model="cityValue" placeholder="市" @change="selectcity">
      <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <el-select placeholder="区" v-model="RegionValue" @change="selectRegion">
      <el-option
          v-for="(item,index) of regionarr"
          :key="index"
          :label="item.name"
          :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import {province} from '@/assets/js/map'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",
  created() {
    this.provincearr = province
  },
  data() {
    return {
      arr: [1, 3, 4],
      province: [],
      cityarr: [],
      regionarr: [],
      provinceValue: '',
      cityValue: '',
      RegionValue: '',
      provinceName:'',
      cityName:'',
      regionName:''
    }
  },
  computed: {
    area() {
      return this.provinceName + this.cityName + this.regionName
    }
  },
  methods: {

    selectProvimce(id) {
      this.cityarr = [];
      this.regionarr = [];
      this.cityValue = '';
      this.RegionValue = '';
      for (let item of this.provincearr) {
        if (id == item.id) {
          this.provinceName=item.name
          this.cityarr = item.children
        }
      }
      this.$emit("change", this.area)
    },
    selectcity(id) {
      this.regionarr = [];
      this.RegionValue = '';
      for (let item of this.cityarr) {
        if (id == item.id) {
          this.cityName=item.name
          this.regionarr = item.children
        }
      }
      this.$emit("change", this.area)
    },
    selectRegion(id) {
      for (let item of this.regionarr) {
        if (id == item.id) {
          this.regionName=item.name
          this.regionarr = item.children
        }
      }
      this.$emit("change", this.area)
    }
  }
}
</script>

<style lang="scss" scoped>
.container_a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ::v-deep .el-input__inner {
    border: transparent;
  }
}

</style>