// 本地域名
let BaseUrl = 'https://www.taoqiandao.com/api'  //8077

module.exports = {
	// 公共
	getCategoryList: BaseUrl + '/apply/get_category_list', //供货商可选的供应资源列表
	provider: BaseUrl + '/apply/provider', //供应商申请接口
	facilitator: BaseUrl + '/apply/facilitator', //服务商申请
	cooperation: BaseUrl + '/apply/cooperation', //渠道商申请

	newsList: BaseUrl + '/api/news/list' //渠道商申
};
