<template>
  <div>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <img src="@/assets/img/banner1.png" class="tenant_item_banner"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/img/banner2.png" class="tenant_item_banner"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/img/banner3.png" class="tenant_item_banner"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/img/banner4.png" class="tenant_item_banner"/>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <Tenant></Tenant>
    <Common1></Common1>
    <Common2></Common2>
    <Common3></Common3>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Tenant from "@/components/home/Tenant";
import Common1 from "@/components/home/Common1";
import Common2 from "@/components/home/Common2";
import Common3 from "@/components/home/Common3";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 3000
        },
        loop: true
      }
    }
  },
  components: {
    Tenant,
    Swiper, SwiperSlide,
    Common1,Common2,Common3
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    }
  }
}
</script>

<style lang="scss" scoped>
.tenant_item_banner {
  height: 420px;
  width: 100%;

}
</style>