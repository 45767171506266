<template>
  <div class="distinct_box">
    <div class="title1">服务优势</div>
    <div class="title2">全面型服务，确保用户随时随地体验到淘钱岛的快乐</div>
    <div class="display_box1">
      <div class="display_box1_item">
        <img src="@/assets/img/beforeSale.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">售前服务</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">提供产品技术咨询以及长期方案的疑难解答</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/trainning1.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">免费培训</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">提供免费的系统和应用培训服务</div>
        </div>

      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/followMarket.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">跟随市场</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">市场变化，跟进脚步，不断更新</div>
        </div>
      </div>
      <div class="display_box1_item">
        <img src="@/assets/img/high_profit.png" class="display_box1_item_img"/>
        <div class="display_box1_item_word1">自买省钱</div>
        <div class="display_box1_item_word2_box">
          <div class="display_box1_item_word2">优选商家/淘钱岛商城大牌特卖全平台自买省钱</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Common4",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title1{
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 50px;
  text-align: center;
  margin-bottom: 16px;
}
.title2{
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-bottom: 38px;
  text-align: center;
  letter-spacing: 1px;
}

.display_box1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.display_box1_item{
  width: 285px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 10px;
  text-align: center;
  padding-top: 28px;
}

.distinct_box{
  background: #F7F8FA;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 60px;
}
.display_box1_item_img{
  width: 120px;
  height: 120px;
}
.display_box1_item_word1{
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 26px;
  margin-top: 16px;
}
.display_box1_item_word2{
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 22px;
  margin-top: 16px;
  width: 200px;
  display: inline-block;
}

.display_box1_item_word2_box{
  text-align: center;
  width: 100%;
}
</style>