<script src="../../../api/api.js"></script>
<template>
  <div class="container">


    <div class="box">
      <div class="title">填写合作信息</div>
      <div class="inputItem">
        <div class="label">联系人姓名：</div>
        <input v-model="name" class="inputBox"/>

      </div>

      <div class="inputItem">
        <div class="label">联系人手机号：</div>
        <input v-model="phone" class="inputBox"/>
      </div>

      <div class="inputItem">
        <div class="label">合作区域：</div>
        <div class="inputBox">
          <Pca @change="get"></Pca>
        </div>
      </div>

      <div class="inputItem">
        <div class="label">详细地区：</div>
        <input v-model="address" class="inputBox"/>
      </div>
      <div class="noticeBox">
        <div class="noticeBox_title"><span style="color:#FF385C;">*</span>申报说明：</div>
        <div class="noticeBox_content">这里是其他信息内容展示这里是其他信息内容展示这里是其他信息内容展示…这里是其他信息内容展示…这里是其他信息内容展示…</div>
      </div>
      <div class="buttonBox">
        <el-button @click="apply">申请并填报信息</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import Pca from '../../pca/index'
import {cooperation, facilitator} from "@/api/index"

export default {
  name: "MyForm1",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pca
  },
  data() {
    return {
      name: '',
      phone: '',
      place: '',
      address: ''
    }
  },
  methods: {
    apply() {
      cooperation({name: this.name, phone: this.phone, place: this.place, address: this.address}).then(() => {
        this.$message.success("申请成功")
      })
    },
    get(place){
      console.log(place)
      this.place=place
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 790px;
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  padding: 100px;

  .title {
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 50px;
    text-align: center;
    margin-bottom: 48px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inputItem {
      width: 500px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 24px;

      .label {
        margin-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        width: 100px;
      }

      .inputBox {
        flex: 1;
        border: transparent;
      }

      .inputBox:focus {
        outline: none;
      }

    }

    .noticeBox {
      width: 500px;
      height: 200px;
      background: #FFFFFF;
      border-radius: 12px;

      .noticeBox_title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF385C;
        line-height: 20px;
        margin-left: 20px;
        margin-top: 30px;
      }

      .noticeBox_content {
        width: 460px;
        margin-top: 10px;
        margin-left: 20px;
        height: 44px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(17, 17, 17, 0.9);
        line-height: 22px;
      }
    }

    .buttonBox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ::v-deep .el-button {
        background: #FF385C;
        color: #FFFFFF;
      }
    }
  }

}
</style>